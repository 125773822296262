import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://gateway-arbitrum.network.thegraph.com/api/c609710db34861c94deb414435d43c75/subgraphs/id/4b6QsHyC6mk9v2ADWbxZYkMKrKkPTeYBJH3GvMn2aCkg',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

// export const healthClient = new ApolloClient({
//   link: new HttpLink({
//     uri: 'https://api.thegraph.com/index-node/graphql',
//   }),
//   cache: new InMemoryCache(),
//   shouldBatch: true,
// })

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://gateway-arbitrum.network.thegraph.com/api/c609710db34861c94deb414435d43c75/subgraphs/id/aFYiBZ2nkQVbv1HsKTQcPpWBxCAiJY4w4pG8RXaDxge',
  }),
  cache: new InMemoryCache(),
})
