export const FACTORY_ADDRESS = '0x4dC6048552e2DC6Eb1f82A783E859157d40FA193'

export const BUNDLE_ID = '1'

export const timeframeOptions = {
  WEEK: '1 week',
  MONTH: '1 month',
  // THREE_MONTHS: '3 months',
  // YEAR: '1 year',
  ALL_TIME: 'All time',
}

// token list urls to fetch tokens from - use for warnings on tokens and pairs
export const SUPPORTED_LIST_URLS__NO_ENS = [
  'https://raw.githubusercontent.com/pancakeswap/pancake-swap-interface/master/src/constants/token/pancakeswap.json',
  'https://asset.benswap.finance/tokens.json'
]

// hide from overview list
export const OVERVIEW_TOKEN_BLACKLIST = [

]

// pair blacklist
export const PAIR_BLACKLIST = []



export const SUPPORTED_LIST_LOCAL = {
  "name": "BenSwap Default List",
  "timestamp": "2021-06-05T19:09:29Z",
  "version": {
    "major": 1,
    "minor": 0,
    "patch": 0
  },
  "tags": {},
  "logoURI": "/coins/BEN.png",
  "keywords": ["benswap", "default"],
  "tokens": [
    {
      "name": "Ben Token",
      "symbol": "BEN",
      "address": "0x8ee4924bd493109337d839c23f628e75ef5f1c4d",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "/coins/BEN.png"
    },
    {
      "name": "Dogecoin",
      "symbol": "DOGE",
      "address": "0xba2ae424d960c26247dd6c32edc70b295c744c43",
      "chainId": 56,
      "decimals": 8,
      "logoURI": "/coins/DOGE.png"
    },
    {
      "name": "Golden Ben",
      "symbol": "GBEN",
      "address": "0x8173dda13fd405e5bca84bd7f64e58caf4810a32",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "/coins/GBEN.png"
    },
    {
      "name": "Green Ben",
      "symbol": "EBEN",
      "address": "0xbb036ccDe5feE48f011B9916646f3a341d7D490A",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "/coins/EBEN.png"
    },
    {
      "name": "Wise Token",
      "symbol": "WISB",
      "address": "0x4f491d389a5bf7c56bd1e4d8af2280fd217c8543",
      "chainId": 56,
      "decimals": 18,
      "logoURI": "/coins/WISB.png"
    },
    {
      "name": "SHIBA INU",
      "symbol": "SHIB",
      "address": "0x2859e4544C4bB03966803b044A93563Bd2D0DD4D",
      "chainId": 56,
      "decimals": 18,
      "logoURI": ""
    }
  ]
}
